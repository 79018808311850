import { reactive } from 'vue';

const store = reactive({
    data: {
        currentId: 1,

        respondentId: '',

        records: [],

        loading: false
    }
});

export default store