<template>
    <div id="menu" class="bg-dark">
      <div class="flex-1" style="padding-left: 1em;">Záznam dopravy</div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" style="width: 2em; height: 2em; margin-top: 0.5em;"   fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </div>
    </div>

    <router-view/>
</template>
<style lang="scss">
@import "~spectre.css";

body {
    overscroll-behavior-y: contain;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.flex-1 {
    flex: 1;
}

#menu {
  display: flex;
  align-items: center;
  font-size: 1.1em;
  padding: 0.2em;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 2em;
}
</style>
