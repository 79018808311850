import {createApp} from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import _ from "lodash";
import IdbService from "./services/IdbService";
import Store from './store';

window.surveyConfig = {
    start: 1,
    languages: ['en', 'cz'],
    end: 501
};

_.forEach(window.surveyConfig.languages, (language) => {
    /*
    IdbService.getTranslations(language)
        .finally(() => {

        });
     */
})

/*
IdbService.getTranslationsEnabled();

IdbService.getLang();

IdbService.getRespondentId();

IdbService.getCurrentId()
    .finally(() => {
        IdbService.getAnswers(Store.data.currentId)
    });
*/

const app = createApp(App)
    .use(router)
    .mount('#app');


