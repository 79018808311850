<template>
    <div class="home">

        <div>
            <div class="padded-div">
                <button
                    @click="newRecord()"
                    class="btn btn-lg btn-primary"
                    :class="{'btn-primary': !structureLoaded}"
                >Nová cesta
                </button>
            </div>
        </div>

        <div class="text-center padded-div" v-if="store.records.length">
            <h1>Zaznamenané cesty:</h1>

            <div class="recorded-routes">
                <div v-for="record in store.records"
                     :key="'record' + record.id"
                     @click="goToRecord(record.id)"
                >
                    <div class="text-bold flex-1">
                        Trasa {{ record.id }}
                    </div>
                    <div>
                        <svg
                            @click.stop="removeRecord(record.id)"
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon milestone__delete"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            stroke-width="2"
                        >
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </div>

                </div>
            </div>
        </div>

        <div class="text-center">
            verze 0.5 <span v-if="store.loading">... loading ...</span>
        </div>


    </div>
</template>

<script>
import Survey from '../services/Survey.js';
import StoreContainer from '../store.js';
import IdbService from "../services/IdbService";
import http from "../services/Http";
// import httpLocal from "../services/HttpLocal";
import httpLocal from "../services/Http";
import mitt from "../services/Mitt";
import Tranlsations from "../services/Translations";
import _ from "lodash";
import Store from "../store";
import {keys, getMany} from "idb-keyval";

export default {
    name: 'Home',

    data() {
        return {
            store: StoreContainer.data,
            structureLoaded: false,
            structureLoadedAt: false,
            translationStatus: {},
            records: []
        }
    },

    mounted() {
        // set structure to indexedDB
        IdbService.loadRoutes()
    },

    computed: {
        downloadedString() {
            if (!this.structureLoadedAt) {
                return '';
            }

            return this.formatDateTime(this.structureLoadedAt)
        }
    },

    methods: {
        formatDateTime(timestamp) {

            let date = new Date()

            date.setTime(timestamp);

            return date.toLocaleDateString(
                'cs-CZ',
                {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                }
            ) + ' ' + date.toLocaleTimeString(
                'cs-CZ'
            );
        },

        newRecord() {
            IdbService.incrementMaxId()
                .finally(() => {
                    IdbService.getMaxId().then((currentId) => {
                        IdbService.setCurrentId(currentId)
                            .finally(() => {
                                IdbService.saveRecord([], currentId)

                                this.$router.push({
                                    name: 'RouteRecorder',
                                    params: {
                                        id: currentId
                                    }
                                });
                            })
                    })
                })
        },

        goToRecord(index) {
            this.$router.push({
                name: 'RouteRecorder',
                params: {
                    id: index
                }
            });
        },

        removeRecord(index) {
            if(confirm('Opravdu? Bu bu bu!')) {
                IdbService.deleteRecord(index).finally(() => {
                    IdbService.loadRoutes()
                });
            }
        }

    }
}
</script>

<style lang="scss">

h1 {
    font-size: 1.3em;
    font-weight: bold;
    // background-color: #e5e5e5;
    padding: 0.5em 0;
}

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2em;
}

.home .padded-div {
    padding: 0 1em;
}

.home .btn-lg {
    width: 100%;
    height: 4em;
    font-size: 1.4em;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.home .data-info {
    display: block;
    margin-top: 2em;
    font-size: 0.9em;
    color: gray;
}

.home .btn {
    text-transform: uppercase;
}

.recorded-routes {
    display: flex;
    flex-direction: column;
}
.recorded-routes > div {
    padding: 1em 0 1em 0;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    text-align: left;
    align-items: center;

    display: flex;
}

.recorded-routes > div:last-child {
    border-bottom: 0 solid gray;
}

.icon {
    width: 1.5em;
    height: 1.5em;
    color: gray;
    display: block;
}

</style>
